// utils/security.js
import React from 'react';
import { sanitizeFileName } from './sanitize';

export class SecurityErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        logError(error, {
            type: 'error boundary',
            errorInfo
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ 
                    padding: '20px', 
                    textAlign: 'center',
                    color: '#666'
                }}>
                    <h2>Something went wrong</h2>
                    <p>Please try again later</p>
                    {process.env.NODE_ENV === 'development' && this.state.error && (
                        <pre style={{ 
                            textAlign: 'left', 
                            padding: '10px',
                            background: '#f5f5f5'
                        }}>
                            {this.state.error.toString()}
                        </pre>
                    )}
                </div>
            );
        }

        return this.props.children;
    }
}

export class RateLimiter {
    constructor(maxRequests = 100, timeWindow = 900000) {
        this.maxRequests = maxRequests;
        this.timeWindow = timeWindow;
        this.tokens = maxRequests;
        this.lastRefill = Date.now();
    }

    canMakeRequest() {
        this.refillTokens();
        if (this.tokens > 0) {
            this.tokens--;
            return true;
        }
        return false;
    }

    refillTokens() {
        const now = Date.now();
        const timePassed = now - this.lastRefill;
        const refillAmount = Math.floor((timePassed / this.timeWindow) * this.maxRequests);
        this.tokens = Math.min(this.maxRequests, this.tokens + refillAmount);
        this.lastRefill = now;
    }
}

export const validateAudioFile = async (file) => {
    const maxFileSize = 2 * 1024 * 1024 * 1024; // 2GB
    const allowedTypes = new Set([
    'audio/mpeg',
    'audio/wav', 
    'audio/ogg', 
    'audio/aac', 
    'audio/flac',
    'audio/mp3',
    'audio/x-m4a',
    'audio/mp4',
    'audio/x-wav',
    'audio/wave',
    'audio/x-pn-wav',
    '' // Empty string for mobile devices
]);
    const allowedExtensions = new Set(['mp3', 'wav', 'ogg', 'aac', 'flac', 'm4a']);

    if (file.size > maxFileSize) {
        throw new Error('File size exceeds 50MB limit');
    }

    // Using sanitizeFileName from sanitize.js
    const sanitizedName = sanitizeFileName(file.name);
    const extension = sanitizedName.split('.').pop().toLowerCase();
    
    if (!allowedExtensions.has(extension)) {
        throw new Error('Invalid file extension');
    }

    if (!allowedTypes.has(file.type)) {
        throw new Error('Invalid file type');
    }

    // Validate file signature
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            try {
                const arr = new Uint8Array(reader.result).subarray(0, 4);
                const header = Array.from(arr).map(b => b.toString(16)).join('');
                
                const validSignatures = {
                    'fff3': 'mp3',
                    'fff2': 'mp3',
                    'fffe': 'mp3',
                    '494433': 'mp3', // ID3
                    '52494646': 'wav', // RIFF
                    '4f676753': 'ogg', // OggS
                };
                
                if (!Object.keys(validSignatures).some(sig => header.startsWith(sig))) {
                    reject(new Error('Invalid file signature'));
                }
                
                resolve(file);
            } catch (error) {
                reject(new Error('File validation failed'));
            }
        };
        reader.onerror = () => reject(new Error('File reading failed'));
        reader.readAsArrayBuffer(file.slice(0, 4));
    });
};

export const setupCSP = () => {
    const meta = document.createElement('meta');
    meta.httpEquiv = "Content-Security-Policy";
    meta.content = `
        default-src 'self';
        script-src 'self' 'unsafe-inline' 'unsafe-eval' 'wasm-unsafe-eval' 
            https://*.googletagmanager.com 
            https://*.google-analytics.com 
            https://cdnjs.cloudflare.com;
        script-src-elem 'self' 'unsafe-inline' 
            https://*.googletagmanager.com 
            https://*.google-analytics.com 
            https://cdnjs.cloudflare.com;
        connect-src 'self' 
            https://cdn.jsdelivr.net 
            https://*.google-analytics.com 
            https://*.analytics.google.com 
            https://analytics.google.com 
            https://*.googletagmanager.com;
        style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
        img-src 'self' data: blob: /api/placeholder 
            https://*.google-analytics.com 
            https://*.googletagmanager.com;
        font-src 'self' https://fonts.gstatic.com;
        media-src 'self' blob: filesystem: mediastream:;
        worker-src 'self' blob:;
        frame-src 'self' https://*.googletagmanager.com;
        frame-ancestors 'none';
        object-src 'none';
    `.replace(/\s+/g, ' ').trim();
    document.head.appendChild(meta);
};

export const logError = (error, context = {}) => {
    const sensitivePatterns = [
        /password/i,
        /token/i,
        /api[_-]?key/i,
        /secret/i,
        /credential/i
    ];

    // Sanitize error data
    const sanitizedContext = JSON.parse(JSON.stringify(context), (key, value) => {
        if (typeof value === 'string' && 
            sensitivePatterns.some(pattern => pattern.test(key))) {
            return '[REDACTED]';
        }
        return value;
    });

    // Log error with context
    const errorData = {
        message: error.message,
        context: sanitizedContext,
        timestamp: new Date().toISOString(),
        stack: process.env.NODE_ENV === 'development' ? error.stack : undefined
    };

    console.error('Application Error:', errorData);
};

export const initializeErrorHandling = () => {
    window.onerror = (msg, url, lineNo, columnNo, error) => {
        logError(error || new Error(msg), {
            location: `${url}:${lineNo}:${columnNo}`,
            type: 'uncaught error'
        });
    };

    window.addEventListener('unhandledrejection', (event) => {
        logError(event.reason || new Error('Unhandled Promise Rejection'), {
            type: 'unhandled rejection'
        });
    });
};