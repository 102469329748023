import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setupCSP, initializeErrorHandling } from './utils/security';
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router
import VideotoAudio from './VideotoAudio';

// Initialize security features
setupCSP();
initializeErrorHandling();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router> {/* Wrap App with Router */}
      <App />
     
    </Router>
  </React.StrictMode>
);

reportWebVitals();
