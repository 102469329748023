import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Download, Trash2, Upload, X } from 'lucide-react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import Navbar from './Navbar';
import { sanitizeFileName } from './utils/sanitize';


import { 
    validateAudioFile, 
    RateLimiter, 
    logError, 
    initializeErrorHandling 
} from './utils/security';

const rateLimiter = new RateLimiter();


const ffmpeg = new FFmpeg();

const PopupMessage = ({ messages, onClose }) => {
    return (
        <div className="popup-container">
            <button className="close-btn" onClick={onClose}>
                <X size={24} />
            </button>
            <div className="popup-message">
                {messages.map((message, index) => (
                    <p key={index} className="message-text">{message}</p>
                ))}
            </div>
            <style jsx>{`
                .popup-container {
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1000;
                }
                .popup-message {
                    background-color: rgba(0, 0, 0, 0.7);
                    color: white;
                    padding: 10px 20px;
                    border-radius: 5px;
                    max-width: 300px;
                    text-align: center;
                    font-size: 14px;
                    max-height: 80vh;
                    overflow-y: auto;
                     word-break: break-word;    /* Add this */
    white-space: normal; 
                }
                .popup-message::-webkit-scrollbar {
                    width: 1.67vh;
                }
                .popup-message::-webkit-scrollbar-track {
                    background-color: rgba(217, 217, 217, 0.3);
                    border-radius: 1.11vh;
                }
                .popup-message::-webkit-scrollbar-thumb {
                    background-color: rgba(255, 255, 255, 0.5);
                    border-radius: 0.74vh;
                    border: 0.37vh solid transparent;
                    background-clip: padding-box;
                }
                .popup-message::-webkit-scrollbar-thumb:hover {
                    background-color: rgba(255, 255, 255, 0.7);
                }
                .close-btn {
                    position: absolute;
                    top: -30px;
                    right: -30px;
                    background: none;
                    border: none;
                    color: white;
                    cursor: pointer;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(0, 0, 0, 0.7);
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                }
                
            `}</style>
        </div>
    );
};
const FileItem = ({ file, onDownload }) => {
    const [shouldScroll, setShouldScroll] = useState(false);
    const contentRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const checkIfShouldScroll = () => {
            if (contentRef.current && containerRef.current) {
                const contentWidth = contentRef.current.offsetWidth;
                const containerWidth = containerRef.current.offsetWidth;
                setShouldScroll(contentWidth > containerWidth - 40);
            }
        };

        checkIfShouldScroll();
        window.addEventListener('resize', checkIfShouldScroll);
        return () => window.removeEventListener('resize', checkIfShouldScroll);
    }, [file.name]);

    return (
        <div className="file-item">
            <button className="download-btn" onClick={() => onDownload(file)}>
                <Download size={20} />
            </button>
            <div className="filename-container" ref={containerRef}>
                {!shouldScroll ? (
                    // Static view for short names
                    <div className="filename-content" ref={contentRef}>
                        <span className="file-name">{file.name}</span>
                    </div>
                ) : (
                    // Scrolling view for long names
                    <>
                        <div ref={contentRef} style={{ position: 'absolute', visibility: 'hidden', whiteSpace: 'nowrap' }}>
                            <span className="file-name">{file.name}</span>
                        </div>
                        <div className="fade-outer fade-left"></div>
                        <div className="fade-inner fade-left"></div>
                        <div className="filename-scroll">
                            <div className="filename-content">
                                <span className="file-name">{file.name}</span>
                                <span className="file-name">{file.name}</span>
                            </div>
                            <div className="filename-content">
                                <span className="file-name">{file.name}</span>
                                <span className="file-name">{file.name}</span>
                            </div>
                        </div>
                        <div className="fade-inner fade-right"></div>
                        <div className="fade-outer fade-right"></div>
                    </>
                )}
            </div>
        </div>
    );
};

const AudioConverter = () => {
    const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
    const [showTwoSquares, setShowTwoSquares] = useState(true);
    const [files, setFiles] = useState({});
    const [loadingFormats, setLoadingFormats] = useState(new Set()); // Changed to Set for format-specific loading
    const [popupMessages, setPopupMessages] = useState([]);
    const fileListRefs = useRef({});
    const fileInputRefs = useRef({});

    const audioFormats = ['mp3', 'wav', 'ogg', 'aac', 'flac'];

    const updateSquareCount = useCallback(() => {
        const squareSize = window.innerHeight * 0.3333;
        const containerWidth = window.innerWidth;
        setShowTwoSquares(containerWidth >= squareSize * 2 + window.innerWidth * 0.1);
    }, []);

   useEffect(() => {
    const loadFFmpeg = async () => {
        const ffmpegTimeout = setTimeout(() => {
            setPopupMessages(['FFmpeg loading timeout. Please refresh the page.']);
        }, 30000); // 30 second timeout
        try {
            await ffmpeg.load();
            clearTimeout(ffmpegTimeout); // Add this line
            setFfmpegLoaded(true);
        } catch (error) {
            console.error('Failed to load FFmpeg:', error);
            clearTimeout(ffmpegTimeout); // Add this line
            setPopupMessages(['Failed to load audio conversion library']);
        }
    };
    initializeErrorHandling();
    loadFFmpeg();
}, []);

    useEffect(() => {
        updateSquareCount();
        window.addEventListener('resize', updateSquareCount);
        return () => window.removeEventListener('resize', updateSquareCount);
    }, [updateSquareCount]);

    useEffect(() => {
        const checkScrollbars = () => {
            audioFormats.forEach(format => {
                const fileList = fileListRefs.current[format];
                if (fileList) {
                    const hasScrollbar = fileList.scrollHeight > fileList.clientHeight;
                    fileList.style.setProperty('--padding-right', hasScrollbar ? '1.11vh' : '0');
                }
            });
        };

        checkScrollbars();
        window.addEventListener('resize', checkScrollbars);
        return () => window.removeEventListener('resize', checkScrollbars);
    }, [files, audioFormats]);

    const convertAudio = async (file, targetFormat) => {
    if (!ffmpegLoaded) {
        const error = new Error('FFmpeg not loaded');
        console.error(error);
        throw error;
    }

    const inputFileName = 'input_file.' + file.name.split('.').pop();
    const outputFileName = `output.${targetFormat}`;

    try {
        await ffmpeg.writeFile(inputFileName, await fetchFile(file));
        await ffmpeg.exec([
            '-i', inputFileName,
            ...(targetFormat === 'mp3' ? ['-b:a', '320k'] : []),
            ...(targetFormat === 'flac' ? ['-compression_level', '8'] : []),
            ...(targetFormat === 'ogg' ? ['-q:a', '10'] : []),
            ...(targetFormat === 'aac' ? ['-b:a', '320k', '-strict', '-2'] : []),
            outputFileName
        ]);

        const data = await ffmpeg.readFile(outputFileName);
        const convertedFile = new File(
            [data.buffer],
            file.name.split('.')[0] + '.' + targetFormat,
            { type: `audio/${targetFormat}` }
        );

        try {
            await Promise.all([
                ffmpeg.deleteFile(inputFileName),
                ffmpeg.deleteFile(outputFileName)
            ]).catch(error => {
                console.error('Cleanup error:', error);
            });
        } catch (error) {
            console.error('Conversion error:', error);
            throw error;
        }

        return convertedFile;
    } catch (error) {
        console.error('Conversion error:', error);
        throw error;
    }
};

const handleFileUpload = async (event, targetFormat) => {
    // GTM tracking for upload attempt
    window.dataLayer?.push({
        'event': 'file_upload',
        'file_format': targetFormat,
        'file_count': event.target?.files?.length || event.dataTransfer?.files?.length || 0
    });

    if (!rateLimiter.canMakeRequest()) {
        setPopupMessages(['Too many requests. Please try again later']);
        return;
    }

    if (!ffmpegLoaded) {
        console.error('Upload attempted before FFmpeg load'); 
        setPopupMessages(['Audio converter is still loading...']);
        return;
    }

    try {
        const uploadedFiles = event.dataTransfer?.files || event.target?.files;
        if (!uploadedFiles) {
            throw new Error('No files provided');
        }

        // First check for same format files
        const sameFormatFiles = Array.from(uploadedFiles).filter(file => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (fileExtension === targetFormat.toLowerCase()) {
                setPopupMessages(prev => [...prev, `"${file.name}" is already in ${targetFormat} format`]);
                return false;
            }
            return true;
        });

        // Then validate remaining files
        const validFiles = await Promise.all(sameFormatFiles.map(async file => {
            try {
                await validateAudioFile(file);
                return true;
            } catch (error) {
                console.error('Invalid file:', error); 
                setPopupMessages(prev => [...prev, `${error.message}: ${file.name}`]);
                return false;
            }
        })).then(results => sameFormatFiles.filter((_, index) => results[index]));

        setLoadingFormats(prev => new Set([...prev, targetFormat]));

        // Process files with sanitized names
        for (const file of validFiles) {
            const sanitizedName = sanitizeFileName(file.name);
            const fileWithSanitizedName = new File([file], sanitizedName, { type: file.type });
            
            try {
                const convertedFile = await convertAudio(fileWithSanitizedName, targetFormat.toLowerCase());
                if (convertedFile) {
                    // GTM tracking for successful conversion
                    window.dataLayer?.push({
                        'event': 'conversion_success',
                        'source_format': file.name.split('.').pop(),
                        'target_format': targetFormat
                    });

                    setFiles(prev => ({
                        ...prev,
                        [targetFormat]: [...(prev[targetFormat] || []), convertedFile]
                    }));
                }
            } catch (error) {
                // GTM tracking for conversion error
                window.dataLayer?.push({
                    'event': 'conversion_error',
                    'error_message': error.message
                });

                console.error('File upload error:', error);
                setPopupMessages(prev => [...prev, `Failed to convert "${sanitizedName}": ${error.message}`]);
            }
        }
    } catch (error) {
        console.error('File upload error:', error);
        setPopupMessages(['An error occurred while processing the files.']);
    } finally {
        setLoadingFormats(prev => {
            const newSet = new Set(prev);
            newSet.delete(targetFormat);
            return newSet;
        });
    }
};

const downloadFile = (file) => {
    // GTM tracking for download
    window.dataLayer?.push({
        'event': 'file_download',
        'file_format': file.name.split('.').pop()
    });

    try {
        // Validate file before download
        if (!file || !(file instanceof File)) {
            throw new Error('Invalid file object');
        }

        // Validate file type
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!audioFormats.includes(fileExtension)) {
            throw new Error('Invalid file type');
        }

        const sanitizedName = sanitizeFileName(file.name);
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = sanitizedName;
        a.rel = 'noopener noreferrer';
        
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 100);
    } catch (error) {
        logError(error, {
            fileName: file?.name,
            operation: 'download'
        });
        setPopupMessages(['Failed to download file']);
    }
};
    const downloadAllFiles = (formatFiles) => {
        formatFiles.forEach(file => {
            downloadFile(file);
        });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e, format) => {
        e.preventDefault();
        e.stopPropagation();
        handleFileUpload(e, format);
    };

    const clearAllFiles = (format) => {
        setFiles(prevFiles => ({
            ...prevFiles,
            [format]: []
        }));
    };

    const triggerFileInput = (format) => {
        if (fileInputRefs.current[format]) {
            fileInputRefs.current[format].value = '';
            fileInputRefs.current[format].click();
        }
    };

    const renderSquare = (format) => (
        <div
            className="square"
            onDrop={(e) => handleDrop(e, format)}
            onDragOver={handleDragOver}
            onClick={() => triggerFileInput(format)}
        >
            <div className="format">.{format.toLowerCase()}</div>
            <input
                type="file"
                multiple
                accept="audio/*,video/*,.wav,.mp3,.aac,.ogg,.flac,audio/wav,audio/mp3,audio/aac,audio/ogg,audio/flac"
                onChange={(e) => handleFileUpload(e, format)}
                style={{ display: 'none' }}
                ref={el => fileInputRefs.current[format] = el}
            />
        </div>
    );

    const renderOutputSquare = (format) => (
        <div className={`square output ${(files[format]?.length || loadingFormats.has(format)) ? 'has-files' : ''}`}>
             {(!files[format]?.length && !loadingFormats.has(format)) ? (
            <div className="format">Output</div>
        ) : (
            <div className="file-list-container">
                <div
                    className="file-list"
                    ref={el => fileListRefs.current[format] = el}
                    onWheel={(e) => e.stopPropagation()}
                >
                    {loadingFormats.has(format) && <div className="loading">Converting...</div>}
                    {files[format]?.map((file, idx) => (
                        <FileItem key={idx} file={file} onDownload={downloadFile} />
                    ))}
                </div>
                {(files[format]?.length > 0 || loadingFormats.has(format)) && (
                    <div className="button-container">
                        {!showTwoSquares && (
                            <button
                                className="action-btn upload-btn"
                                onClick={() => triggerFileInput(format)}
                            >
                                {format.toLowerCase()}
                            </button>
                        )}
                        {files[format]?.length > 0 && (
                            <>
                                <button
                                    className="action-btn download-all-btn"
                                    onClick={() => downloadAllFiles(files[format])}
                                >
                                    <Download size={20} /> Download All
                                </button>
                                <button
                                    className="action-btn clear-all-btn"
                                    onClick={() => clearAllFiles(format)}
                                >
                                    <Trash2 size={20} />
                                </button>
                            </>
                        )}
                    </div>
                )}
            </div>
        )}
            <input
                type="file"
                multiple
               accept="audio/*,video/*,.wav,.mp3,.aac,.ogg,.flac,audio/wav,audio/mp3,audio/aac,audio/ogg,audio/flac"
                onChange={(e) => handleFileUpload(e, format)}
                style={{ display: 'none' }}
                ref={el => fileInputRefs.current[format] = el}
            />
        </div>
    );

    return (



        <div className="container">
            <Navbar />
            {popupMessages.length > 0 && (
                <PopupMessage
                    messages={popupMessages}
                    onClose={() => setPopupMessages([])}
                />
            )}
            {audioFormats.map((format, index) => (
                <div className="squares" key={index} data-format={format}>
                    {showTwoSquares ? (
                        <>
                            {renderSquare(format)}
                            {renderOutputSquare(format)}
                        </>
                    ) : (
                        loadingFormats.has(format) || files[format]?.length > 0
                            ? renderOutputSquare(format)
                            : renderSquare(format)
                    )}
                </div>
            ))}
            
            <style jsx>{`
                .container {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 33.33vh;
                    padding: 33.33vh 0;
                    box-sizing: border-box;
                    width: 100%;
                    min-height: 100vh;
                    
                }
                .squares {
                    display: flex;
                    gap: 10vw;
                    width: 100%;
                    justify-content: center;
                }
                .square {
                    width: 33.33vh;
                    height: 33.33vh;
                    background-color: #FF2D55;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    border-radius: 1.11vh;
                    box-sizing: border-box;
                }
                .format {
                    color: white;
                    font-size: 5.28vh;
                    font-weight: bold;
                    pointer-events: none;
                     font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
                }
                .output {
                    overflow: hidden;
                    background: #D9D9D9;
                    border: none;
                    padding: 0;
                    
                    
                }
                .output.has-files {
                    background: transparent;
                }
                .file-list-container {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    width: 100%;
                    font-family: "Lexend", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
                }
                .file-list {
                    flex: 1;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 1.11vh;
                    padding-right: var(--padding-right, 0);
                    overscroll-behavior: contain; 
                }
                .file-list::-webkit-scrollbar {
                    width: 1.67vh;
                }
                .file-list::-webkit-scrollbar-track {
                    background-color: #D9D9D9;
                    border-radius: 1.11vh;
                }
                .file-list::-webkit-scrollbar-thumb {
                   background-color: rgba(27, 31, 38, 0.72);
                    border-radius: 0.74vh;
                    border: 0.37vh solid transparent;
                    background-clip: padding-box;
                }
                .file-list::-webkit-scrollbar-thumb:vertical {
                    min-height: 24px;
                }
                .file-list::-webkit-scrollbar-button {
                    height: 0vh;
                    background-color: #000;
                }
                .file-item {
                    display: flex;
                    align-items: center;
                    height: 4.63vh;
                    gap: 1.11vh;
                    padding: 0 0;
                }
                .filename-container {
                    flex: 1;
                    overflow: hidden;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    background: #EBEDF0;
                    border-radius: 1.11vh;
                    position: relative;
                }
                .fade-outer, .fade-inner {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    z-index: 2;
                }
                .fade-outer.fade-left {
                    width: 1.11vh;
                    left: 1.11vh;
                    background: linear-gradient(to right, #EBEDF0, rgba(235,237,240,0));
                }
               .fade-outer.fade-right {
                    width: 1.11vh;
                    right: 1.11vh;
                    background: linear-gradient(to left, #EBEDF0, rgba(235,237,240,0));
                }
                .fade-inner.fade-left {
                    width: 1.11vh;
                    left: 0px;
                    background: #EBEDF0;
                }
                .fade-inner.fade-right {
                    width: 1.11vh;
                    right: 0px;
                    background: #EBEDF0;
                }
                .filename-scroll {
                    display: flex;
                    white-space: nowrap;
                    animation: scroll-left 20s linear infinite;
                   
                }
                .filename-content {
                    display: flex;
                    padding: 0 1.11vh;
                }
             .file-name {
                    color: #000;
                    font-size: 1.67vh;
                    font-weight: 500;
                    padding-right: 2.22vh;
                }
               .download-btn {
                    background-color: #007AFF;
                    color: white;
                    border: none;
                    width: 4.63vh;
                    height: 4.63vh;
                    border-radius: 1.11vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    padding: 0;
                }
                .download-btn svg {
                    width: 2.22vh; 
                    height: 2.22vh;               
                }
                .download-btn:hover {
                    background-color: #0056b3;
                }
                 .button-container {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 1.11vh;
                }
                .action-btn {
                    color: white;
                    border: none;
                    height: 4.63vh;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1.11vh;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    padding: 0 1.11vh;
                }
                .upload-btn {
                    background-color: #FF9500;
                    flex-grow: 1;
                    margin-right: 1.11vh;
                    width:13.33vh;
                    border-radius:1.11vh;
                    font-family: "DM Sans", sans-serif;
font-optical-sizing: auto;
font-weight: 400;
font-style: normal;
                }
                .upload-btn:hover {
                    background-color: #138496;
                }
                   .download-all-btn {
                    background-color: #34C759;
                    flex-grow: 2;
                    font-size: 1.48vh;
                    border-radius: 1.11vh;
                    font-family: "Lexend", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
                }
                .download-all-btn:hover {
                    background-color: #218838;
                }
                .clear-all-btn {
                    background-color: #FF3B30;
                    width: 4.63vh;
                    height: 4.63vh;
                    margin-left: 1.11vh;
                    border-radius: 1.11vh;
                }
                .clear-all-btn:hover {
                    background-color: #c82333;
                }
             .action-btn svg {
  width: 2.22vh;
  height: 2.22vh;
}
                .loading {
                    height: 4.63vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #333;
                    font-size: 14px;
                    background: #ffffff;
                    padding: 0 1.11vh;
                }
                @keyframes scroll-left {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(-50%);
                    }
                }
            `}</style>

        </div>
    );
};


export default AudioConverter;

