// utils/sanitize.js
import DOMPurify from 'dompurify';

export const sanitizeFileName = (fileName) => {
    // Remove any path traversal attempts
    const sanitized = fileName.replace(/^.*[\\/:]/g, '');
    
    // Sanitize using DOMPurify and restrict to safe characters
    const cleaned = DOMPurify.sanitize(sanitized)
        .replace(/[^\w\s.-]/g, '_')
        .replace(/\.{2,}/g, '.')
        .trim();
        
    // Ensure reasonable length
    return cleaned.slice(0, 255);
};