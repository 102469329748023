import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

const Home = () => {
  const navigate = useNavigate();

  const handleAudioClick = () => {
    console.log('Navigating to AudioConverter...');
    navigate('audioconverter');
  };

  const handleVideoClick = () => {
    console.log('Navigating to VideotoAudio...');
    navigate('videotoaudio');
  };

  return (
    <div className="home">
      <Navbar />
      <div className="hero-section">
        <div className="converters-container">
          <div className="converter-box audio-box">
            <div className="box-inner" onClick={handleAudioClick}>
              <span className="box-text">Audio Format Convert</span>
            </div>
          </div>
          <div className="converter-box video-box">
            <div className="box-inner" onClick={handleVideoClick}>
              <span className="box-text">Video to Audio</span>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .home {
          min-height: 100vh;
          width: 100%;
          background-color: white;
          overflow-x: hidden;
        }

        .hero-section {
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
        }

        .converters-container {
          display: flex;
          gap: 1.11vh;
        }

        .converter-box {
          width: 33.33vh;
          height: 33.33vh;
          border-radius: 1.11vh;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          padding: 2.22vh;
          box-sizing: border-box;
        }

        .audio-box {
          background-color: #FF2D55;
        }

        .video-box {
          background-color: #5856D6;
        }

        .box-text {
          font-family: "DM Sans", sans-serif;
          font-optical-sizing: auto;
          font-weight: 400;
          font-style: normal;
          font-size: 2.59vh;
          color: white;
          letter-spacing: 1px;
          text-align: center;
          width: 100%;
        }

        .box-inner {
          width: 100%;
          height: 100%;
          border: 0px solid #E5E7EB;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        @media (max-height: 600px) {
          .converter-box {
            width: 200px;
            height: 200px;
          }
        }

        @media screen and (max-width: calc(67.77vh)) {
          .converters-container {
            flex-direction: column;
          }

          .converter-box {
            width: 33.33vh;
            height: 33.33vh;
          }

          .box-text {
            font-size: 2.59vh;
          }
        }
      `}</style>
    </div>
  );
};

export default Home;