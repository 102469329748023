import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="wrapper">
      <div className="page-container">
        <div className="content">
          <h1 className="title">Cookie Policy</h1>
          <p className="last-updated">Last Updated: November 2024</p>

          <div className="text-section">
            <h2 className="subtitle">1. What Are Cookies</h2>
            <p>
              Cookies are small text files that are stored on your device when you visit a website. 
              They help provide you with a better browsing experience and allow websites to remember your preferences.
            </p>
          </div>

          <div className="text-section">
            <h2 className="subtitle">2. How We Use Cookies</h2>
            <p>Ttoni uses cookies and similar technologies for the following purposes:</p>
            
            <h3 className="subtitle-small">Essential Cookies</h3>
            <ul className="features-list">
              <li>To ensure the website functions properly</li>
              <li>To maintain basic website functionality</li>
              <li>To remember file format preferences</li>
              <li>To optimize conversion settings</li>
              <li>These cookies cannot be disabled</li>
            </ul>

            <h3 className="subtitle-small">Performance Cookies</h3>
            <ul className="features-list">
              <li>To enhance conversion performance</li>
              <li>To remember quality settings</li>
              <li>To optimize file processing</li>
              <li>To improve conversion speed</li>
            </ul>

            <h3 className="subtitle-small">Google AdSense Cookies</h3>
            <ul className="features-list">
              <li>To display relevant advertisements</li>
              <li>To measure ad performance and effectiveness</li>
              <li>To prevent the same ads from appearing too often</li>
              <li>To remember your ad preferences</li>
            </ul>

            <h3 className="subtitle-small">Analytics Cookies</h3>
            <ul className="features-list">
              <li>To understand how visitors use our website</li>
              <li>To track conversion patterns and preferences</li>
              <li>To monitor audio and video processing performance</li>
              <li>To improve overall website experience</li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">3. Specific Cookies We Use</h2>
            
            <h3 className="subtitle-small">Service Cookies:</h3>
            <ul className="features-list">
              <li><strong>format_pref:</strong> Remembers your preferred conversion formats</li>
              <li><strong>quality_settings:</strong> Stores your quality preferences</li>
              <li><strong>conversion_type:</strong> Tracks whether you're using audio or video conversion</li>
            </ul>

            <h3 className="subtitle-small">Google AdSense Cookies:</h3>
            <ul className="features-list">
              <li><strong>__gads:</strong> Used to register what ads have been displayed</li>
              <li><strong>__gac:</strong> Contains campaign related information</li>
              <li><strong>IDE:</strong> Used for targeting and advertising</li>
              <li><strong>ANID:</strong> Used for advertising purposes</li>
            </ul>

            <h3 className="subtitle-small">Google Analytics Cookies:</h3>
            <ul className="features-list">
              <li><strong>_ga:</strong> Used to distinguish users</li>
              <li><strong>_gid:</strong> Used to distinguish users</li>
              <li><strong>_gat:</strong> Used to throttle request rate</li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">4. Your Cookie Choices</h2>
            <p>You have several options to control or limit how we and our partners use cookies:</p>
            <ul className="features-list">
              <li>Browser Controls: You can manage cookies through your browser settings to:
                <ul className="features-list">
                  <li>Delete all cookies</li>
                  <li>Block all cookies</li>
                  <li>Allow only specific cookies</li>
                </ul>
              </li>
              <li>Google Ad Settings:
                <ul className="features-list">
                  <li>Visit <a href="https://www.google.com/settings/ads" target="_blank" rel="noopener noreferrer">Google Ads Settings</a> to manage personalized advertising</li>
                  <li>Use Google's <a href="https://optout.aboutads.info/" target="_blank" rel="noopener noreferrer">opt-out tool</a></li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">5. Impact of Disabling Cookies</h2>
            <p>Please note that disabling cookies may affect the functionality of our website:</p>
            <ul className="features-list">
              <li>Conversion preferences may not be saved</li>
              <li>Quality settings will reset each visit</li>
              <li>Format selection won't be remembered</li>
              <li>Performance optimizations may be limited</li>
              <li>You may see less relevant advertisements</li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">6. Updates to This Policy</h2>
            <p>We may update this Cookie Policy from time to time. Changes will be posted on this page with an updated revision date.</p>
          </div>

          <div className="text-section">
            <h2 className="subtitle">7. Contact Us</h2>
            <p>If you have questions about our Cookie Policy, please contact us at srirsk.business@gmail.com</p>
          </div>
        </div>
      </div>

      <style jsx>{`
        .wrapper {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }

        .page-container {
          flex: 1;
          box-sizing: border-box;
          width: 100%;
          background-color: white;
        }

        .content {
          box-sizing: border-box;
          max-width: 800px;
          margin: 0 auto;
          font-family: "Lexend", sans-serif;
          padding: 4.44vh 2.22vh;
        }

        .title {
          font-family: "DM Sans", sans-serif;
          font-size: 3.33vh;
          font-weight: bold;
          color: #1b1f26;
          margin-bottom: 2.22vh;
          text-align: center;
        }

        .last-updated {
          color: #666;
          font-size: 1.67vh;
          margin-bottom: 2.22vh;
          text-align: center;
        }

        .subtitle {
          font-family: "DM Sans", sans-serif;
          font-size: 2.22vh;
          font-weight: bold;
          color: #1b1f26;
          margin-bottom: 1.11vh;
        }

        .subtitle-small {
          font-family: "DM Sans", sans-serif;
          font-size: 1.89vh;
          font-weight: bold;
          color: #1b1f26;
          margin-top: 1.67vh;
        }

        .text-section {
          margin-bottom: 2.22vh;
          padding: 1.67vh;
          background-color: #f8f9fa;
          border-radius: 0.83vh;
        }

        .text-section p {
          font-size: 1.67vh;
          line-height: 1.6;
          color: #666;
        }

        .features-list {
          list-style-type: disc;
          padding-left: 2.22vh;
          margin: 1.11vh 0;
        }

        .features-list li {
          font-size: 1.67vh;
          color: #666;
          margin: 0.83vh 0;
          line-height: 1.4;
        }

        .features-list ul {
          margin: 0.56vh 0 0.56vh 1.67vh;
        }

        strong {
          color: #1b1f26;
          font-weight: 500;
        }

        a {
          color: #2563eb;
          text-decoration: none;
          transition: color 0.3s ease;
        }

        a:hover {
          color: #1d4ed8;
          text-decoration: underline;
        }

        @media (max-width: 767px) {
          .content {
            padding: 3.33vh 1.67vh;
          }

          .title {
            font-size: 2.78vh;
          }

          .text-section {
            padding: 1.11vh;
          }
        }
      `}</style>
    </div>
  );
};

export default CookiePolicy;