import React from 'react';

const Terms = () => {
  return (
    <div className="wrapper">
      <div className="page-container">
        <div className="content">
          <h1 className="title">Terms of Service</h1>
          <p className="last-updated">Last Updated: November 2024</p>

          <div className="text-section">
            <h2 className="section-heading">1. Service Usage</h2>
            <ul className="features-list">
              <li>Ttoni provides the following services free of charge:
                <ul>
                  <li>Audio format conversion</li>
                  <li>Video-to-audio extraction</li>
                </ul>
              </li>
              <li>Users must have legal rights to:
                <ul>
                  <li>Convert uploaded audio files</li>
                  <li>Extract audio from uploaded videos</li>
                  <li>Use and distribute converted content</li>
                </ul>
              </li>
              <li>All processing occurs locally in the user's browser</li>
              <li>No files are stored on our servers</li>
              <li>500MB maximum file size for video uploads</li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="section-heading">2. User Responsibilities</h2>
            <ul className="features-list">
              <li>Content Guidelines:
                <ul>
                  <li>Users must not upload malicious files</li>
                  <li>Content must not violate any laws</li>
                  <li>Users must have rights to the uploaded content</li>
                </ul>
              </li>
              <li>Technical Responsibilities:
                <ul>
                  <li>Users are responsible for maintaining backups</li>
                  <li>Ensure adequate device resources for conversion</li>
                  <li>Maintain stable internet connection during use</li>
                </ul>
              </li>
              <li>Legal Compliance:
                <ul>
                  <li>Copyright and intellectual property laws</li>
                  <li>Content licensing requirements</li>
                  <li>Fair use guidelines</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="section-heading">3. Service Limitations</h2>
            <ul className="features-list">
              <li>Technical Limitations:
                <ul>
                  <li>Service availability depends on browser compatibility</li>
                  <li>Processing speed varies by device capabilities</li>
                  <li>Video file size limit of 500MB</li>
                  <li>Conversion quality depends on source material</li>
                </ul>
              </li>
              <li>Service Availability:
                <ul>
                  <li>No guarantee of service uptime</li>
                  <li>Features may change without notice</li>
                  <li>Service may be unavailable during maintenance</li>
                </ul>
              </li>
              <li>Quality Considerations:
                <ul>
                  <li>Output quality limited by input quality</li>
                  <li>Some quality loss may occur in compression</li>
                  <li>Not all formats support all quality settings</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="section-heading">4. Intellectual Property</h2>
            <ul className="features-list">
              <li>User Content:
                <ul>
                  <li>Users retain all rights to their audio files</li>
                  <li>Users retain all rights to their video content</li>
                  <li>No content is stored or transmitted to our servers</li>
                </ul>
              </li>
              <li>Service Property:
                <ul>
                  <li>Ttoni name and logo are protected trademarks</li>
                  <li>Interface and design are copyrighted</li>
                  <li>Conversion technology is proprietary</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="section-heading">5. Quality and Performance</h2>
            <ul className="features-list">
              <li>Audio Conversion Quality:
                <ul>
                  <li>MP3: Up to 320 kbps bitrate</li>
                  <li>FLAC: Lossless compression (level 8)</li>
                  <li>OGG: Maximum quality (q10)</li>
                  <li>AAC: Up to 320 kbps</li>
                </ul>
              </li>
              <li>Video Extraction Quality:
                <ul>
                  <li>Maintains original audio quality when possible</li>
                  <li>Supports high-quality audio extraction</li>
                  <li>Quality settings match audio conversion</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <style jsx>{`
        .wrapper {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }

        .page-container {
          flex: 1;
          box-sizing: border-box;
          width: 100%;
          background-color: white;
        }

        .content {
          box-sizing: border-box;
          max-width: 800px;
          margin: 0 auto;
          font-family: "Lexend", sans-serif;
          padding: 4.44vh 2.22vh;
        }

        .title {
          font-family: "DM Sans", sans-serif;
          font-size: 3.33vh;
          font-weight: bold;
          color: #1b1f26;
          margin-bottom: 2.22vh;
          text-align: center;
        }

        .last-updated {
          color: #666;
          font-size: 1.67vh;
          margin-bottom: 2.22vh;
          text-align: center;
        }

        .section-heading {
          font-family: "DM Sans", sans-serif;
          font-size: 2.22vh;
          font-weight: bold;
          color: #1b1f26;
          margin-bottom: 1.11vh;
        }

        .text-section {
          margin-bottom: 2.22vh;
          padding: 1.67vh;
          background-color: #f8f9fa;
          border-radius: 0.83vh;
        }

        .text-section p {
          font-size: 1.67vh;
          line-height: 1.6;
          color: #666;
        }

        .features-list {
          list-style-type: disc;
          padding-left: 2.22vh;
          margin: 1.11vh 0;
        }

        .features-list li {
          font-size: 1.67vh;
          color: #666;
          margin: 0.83vh 0;
          line-height: 1.4;
        }

        .features-list li ul {
          list-style-type: circle;
          margin: 0.56vh 0 0.56vh 1.67vh;
        }

        @media (max-width: 767px) {
          .content {
            padding: 3.33vh 1.67vh;
          }

          .title {
            font-size: 2.78vh;
          }

          .text-section {
            padding: 1.11vh;
          }
        }
      `}</style>
    </div>
  );
};

export default Terms;