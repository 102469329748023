import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };
  
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-brand">
            <h3 className="footer-title" onClick={() => handleNavigation('/')} style={{ cursor: 'pointer' }}>
              Ttoni
            </h3>
            <p className="footer-description">
              Convert audio files and extract audio from videos
            </p>
          </div>
          
          <div className="footer-links-section">
            <div className="footer-links">
              <button 
                onClick={() => handleNavigation('/about')} 
                className="footer-link"
              >
                About Us
              </button>
              <button 
                onClick={() => handleNavigation('/howitworks')} 
                className="footer-link"
              >
                How It Works
              </button>
             
              <button 
                onClick={() => handleNavigation('/privacy')} 
                className="footer-link"
              >
                Privacy Policy
              </button>
              <button 
                onClick={() => handleNavigation('/cookies')} 
                className="footer-link"
              >
                Cookie Policy
              </button>
              <button 
                onClick={() => handleNavigation('/terms')} 
                className="footer-link"
              >
                Terms of Use
              </button>
            </div>
            
            <p className="footer-copyright">
              © {currentYear} Ttoni. All rights reserved.
            </p>
          </div>
        </div>
      </div>

      <style jsx>{`
        .footer {
          width: 100%;
          background-color: #f5f5f5;
          border-top: 1px solid #e5e5e5;
        }
        
        .footer-container {
          max-width: 120rem;
          margin: 0 auto;
          padding: 3.7vh 2.22vh;
        }
        
        .footer-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2.22vh;
        }
        
        .footer-brand {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        
        .footer-title {
          font-family: "DM Sans", sans-serif;
          font-size: 2.22vh;
          font-weight: bold;
          color: #1b1f26;
          margin-bottom: 1.11vh;
        }
        
        .footer-description {
          font-family: "Lexend", sans-serif;
          font-size: 1.48vh;
          color: #666;
        }
        
        .footer-links-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.48vh;
        }
        
        .footer-links {
          display: flex;
          gap: 2.22vh;
          margin-bottom: 1.11vh;
          flex-wrap: wrap;
          justify-content: center;
        }
        
        .footer-link {
          font-family: "Lexend", sans-serif;
          font-size: 1.48vh;
          color: #666;
          text-decoration: none;
          transition: color 0.3s ease;
          white-space: nowrap;
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
        }
        
        .footer-link:hover {
          color: #333;
        }
        
        .footer-copyright {
          font-family: "Lexend", sans-serif;
          font-size: 1.30vh;
          color: #888;
          text-align: center;
        }
        
        @media (min-width: 768px) {
          .footer-content {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
          }
          
          .footer-brand {
            align-items: flex-start;
          }
          
          .footer-links-section {
            align-items: flex-end;
          }
          
          .footer-links {
            justify-content: flex-end;
          }
          
          .footer-copyright {
            text-align: right;
          }
        }
        
        @media (max-width: 767px) {
          .footer-links {
            padding: 0 1.11vh;
          }
        }
      `}</style>
    </footer>
  );
};

export default Footer;