import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GTMProvider = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        // Initialize GTM only once
        if (!window.dataLayer) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
        }

        // Push page view on each route change
        window.dataLayer.push({
            event: 'page_view',
            page_path: location.pathname + location.search,
            page_location: window.location.href
        });
    }, [location]);

    return children;
};

export default GTMProvider;
