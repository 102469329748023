import React, { useState, useEffect } from 'react';

import { useLocation, Link } from 'react-router-dom';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const audioFormats = ['mp3', 'wav', 'ogg', 'aac', 'flac'];

  const location = useLocation();
const shouldShowFormats = location.pathname === '/audioconverter' || location.pathname === '/videotoaudio';

 useEffect(() => {
  const handleScroll = () => {
    const isScrolled = window.scrollY > 20;
    setScrolled(isScrolled || (location.pathname !== '/' && 
                             location.pathname !== '/audioconverter' && 
                             location.pathname !== '/videotoaudio'));
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, [location.pathname]);

  const scrollToSection = (format) => {
    const element = document.querySelector(`.squares[data-format="${format}"]`);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middleOfElement = absoluteElementTop - (window.innerHeight / 2) + (elementRect.height / 2);

      window.scrollTo({
        top: middleOfElement,
        behavior: 'smooth'
      });
    }
    setIsOpen(false);
  };

  return (
    <>
      <nav className={`navbar ${scrolled || location.pathname === '/' ? 'scrolled' : ''}`}>
        <div className="nav-content">
          <div className="desktop-view">
  {(!scrolled || location.pathname === '/') && (
    <div className={`logo-container ${location.pathname === '/' ? 'home-logo' : ''}`}>
  <Link to="/" className="logo-link">
    <h1 className="logo">TTONI</h1>
  </Link>
</div>
  )}
  {shouldShowFormats && (
    <div className="nav-links">
              {audioFormats.map((format) => (
                <button
                  key={format}
                  onClick={() => scrollToSection(format)}
                  className="nav-link"
                >
                  {format.toLowerCase()}
                </button>
              ))}
            </div>
            )}
          </div>
          
         <div className={`mobile-view ${location.pathname === '/' ? 'home-logo' : ''}`}>
  <Link to="/" className="logo-link">
    <h1 className="logo">TTONI</h1>
  </Link>
            {shouldShowFormats && (
            <button 
              className={`hamburger ${isOpen ? 'open' : ''}`}
              onClick={() => setIsOpen(!isOpen)}
              aria-label="Menu"
            >
              <div className="lines-container">
                <span className="line"></span>
                <span className="line"></span>
              </div>
            </button>
            )}
          </div>
{shouldShowFormats && (
          <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
            <div className="mobile-content">
              <div className="mobile-links">
                {audioFormats.map((format) => (
                  <button
                    key={format}
                    onClick={() => scrollToSection(format)}
                    className="nav-link"
                  >
                    {format.toLowerCase()}
                  </button>
                ))}
              </div>
            </div>
          </div>
)}
        </div>
      </nav>

      <style jsx>{`

      .logo-link {
  text-decoration: none;
  cursor: pointer;
}


        .navbar {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          height: 20vh;
          background-color: white;
          transition: all 0.3s ease;
          z-index: 1000;
            box-shadow: 0 0.5px 0px rgba(0, 0, 0, 0.1);
        }

        .navbar.scrolled {
          height: 67px;
            box-shadow: 0 0.5px 0px rgba(0, 0, 0, 0.1);
        }

        .nav-content {
          position: relative;
          max-width: 1200px;
          margin: 0 auto;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        .desktop-view {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        .logo-container {
          display: flex;
          justify-content: center;
          padding-top: 4.17vh;
        }

        .logo-container.home-logo {
  padding-top: 0;
  height: 100%;
  align-items: center;
}

.mobile-view.home-logo {
  height: 100%;
  align-items: center;
  justify-content: center;
}

.mobile-view.home-logo .logo {
  position: relative;
  top: 0;
  transform: none;
}

        .logo {
          font-family: "DM Sans", sans-serif;
          font-size: 3.33vh;
          font-weight: 700;
          color: #1B1F26;
          margin: 0;
        }

        .mobile-view {
          display: none;
        }

        .hamburger {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          width: 28px;
          height: 20px;
          background: none;
          border: none;
          cursor: pointer;
          padding: 0;
          z-index: 1001;
        }

        .lines-container {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;
        }

        .line {
          display: block;
          width: 100%;
          height: 2px;
          background-color: #1B1F26;
          
          transition: all 0.3s ease;
        }

        .hamburger.open .line:first-child {
          transform: translateY(5px) rotate(45deg);
        }

        .hamburger.open .line:last-child {
          transform: translateY(-5px) rotate(-45deg);
        }

        .nav-links {
          display: flex;
          justify-content: center;
          gap: 16px;
          position: ${scrolled ? 'static' : 'absolute'};
          bottom: ${scrolled ? 'auto' : '1.20vh'};
          left: 0;
          right: 0;
          height: ${scrolled ? '67px' : 'auto'};
          align-items: ${scrolled ? 'center' : 'flex-start'};
        }

        .nav-link {
          background: none;
          border: none;
       font-family: "Lexend", sans-serif;
          font-size: 2.04vh;
          color: #1B1F26;
          padding: 0px 16px;
          cursor: pointer;
          transition: all 0.2s ease;
          border-radius: 8px;
        }

        .nav-link:hover {
          background-color: #f5f5f5;
        }

        .mobile-menu {
          display: none;
        }

        @media (max-width: 768px) {
          .desktop-view {
            display: none;
          }

          .mobile-view {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 67px;
            padding: 0 20px;
            background-color: white;
            box-shadow: 0 0.5px 0px rgba(0, 0, 0, 0.1);
          }

          .mobile-view .logo {
            font-size: 3.33vh;
          }

          .navbar {
            height: 67px;
            background: none;
            box-shadow: none;
          }

          .mobile-menu {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;
            transform: translateX(100%);
            transition: transform 0.3s ease;
            opacity: 0;
            visibility: hidden;
            z-index: 1000;
          }

          .mobile-menu.open {
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
          }

          .mobile-content {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .mobile-links {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            width: 100%;
            padding: 0 20px;
          }

          .mobile-links .nav-link {
            font-size: 18px;
            padding: 12px 16px;
            width: 100%;
            text-align: center;
            opacity: 0;
            transform: translateY(20px);
            transition: all 0.3s ease;
          }

          .mobile-menu.open .nav-link {
            opacity: 1;
            transform: translateY(0);
          }

          .mobile-menu.open .nav-link:nth-child(1) { transition-delay: 0.1s; }
          .mobile-menu.open .nav-link:nth-child(2) { transition-delay: 0.2s; }
          .mobile-menu.open .nav-link:nth-child(3) { transition-delay: 0.3s; }
          .mobile-menu.open .nav-link:nth-child(4) { transition-delay: 0.4s; }
          .mobile-menu.open .nav-link:nth-child(5) { transition-delay: 0.5s; }
        }
      `}</style>
    </>
  );
};

export default Navbar;