import './App.css';
import { Routes, Route } from 'react-router-dom';
import Footer from './Footer/Footer';
import Home from './Home'; // Import the new Home component
import About from './Footer/about';
import HowItWorks from './Footer/howitworks';
import Terms from './Footer/termsofservice';
import Privacy from './Footer/privacypolicy';
import CookiePolicy from './Footer/cookiepolicy';
import { SecurityErrorBoundary } from './utils/security';
import GTMProvider from './GTMProvider';
import AudioConverter from './AudioConverter';  // or whatever your exact file name is
import VideotoAudio from './VideotoAudio.js';

const App = () => {
  return (
    <SecurityErrorBoundary>
      <GTMProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} /> {/* Changed from Square to Home */} 
           <Route path="/audioconverter" element={<AudioConverter />} />
            <Route path="VideotoAudio" element={<VideotoAudio />} />
            
            <Route path="/about" element={<About />} />
            <Route path="/howitworks" element={<HowItWorks />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/cookies" element={<CookiePolicy />} />
          </Routes>
          <Footer />
        </div>
      </GTMProvider>
    </SecurityErrorBoundary>
  );
};

export default App;