import React from 'react';
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
  const navigate = useNavigate();
  
  return (
    <div className="wrapper">
      <div className="page-container">
        <div className="content">
          <h1 className="title">Privacy Policy</h1>
          <p className="last-updated">Last Updated: November 2024</p>

          <div className="text-section">
            <h2 className="subtitle">1. Data Collection</h2>
            <p>Ttoni prioritizes your privacy and maintains minimal data collection practices:</p>
            <ul className="features-list">
              <li>No personal data is collected by Ttoni</li>
              <li>No account creation required</li>
              <li>No audio or video files are stored or transmitted</li>
              <li>No processing data is retained after conversion</li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">2. Local Processing</h2>
            <p>All processing is done locally on your device:</p>
            <ul className="features-list">
              <li>All file conversions occur in your browser:
                <ul>
                  <li>Audio format conversions</li>
                  <li>Video-to-audio extractions</li>
                </ul>
              </li>
              <li>Files never leave your device</li>
              <li>No server uploads or downloads</li>
              <li>Complete privacy protection for your media files</li>
              <li>Temporary processing data is automatically cleared</li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">3. File Handling</h2>
            <p>Our approach to handling your files:</p>
            <ul className="features-list">
              <li>Files are processed entirely in your browser's memory</li>
              <li>No temporary files are stored on your device</li>
              <li>Original and converted files remain private</li>
              <li>Video content is processed solely for audio extraction</li>
              <li>All processing data is cleared when you leave the page</li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">4. Cookies and Third-Party Services</h2>
            <p>We use cookies and similar tracking technologies on our website. For detailed information about the cookies we use and your choices regarding cookies, please see our <button onClick={() => navigate('/cookies')} className="link-button">Cookie Policy</button>.</p>
            
            <h3 className="subtitle-small">Third-Party Services:</h3>
            <ul className="features-list">
              <li>Google AdSense for advertising</li>
              <li>Google Analytics for website usage statistics</li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">5. Your Choices</h2>
            <p>You have control over your data and privacy:</p>
            <ul className="features-list">
              <li>You can opt out of personalized advertising by visiting Google's <a href="https://www.google.com/settings/ads" target="_blank" rel="noopener noreferrer">Ads Settings</a></li>
              <li>You can manage cookies through your browser settings</li>
              <li>You may use ad-blocking software, though this may affect site functionality</li>
              <li>You can choose to clear your browser cache after using the service</li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">6. Technical Data</h2>
            <p>Limited technical data is collected for service improvement:</p>
            <ul className="features-list">
              <li>Basic usage analytics may be collected:
                <ul>
                  <li>Conversion types (audio/video)</li>
                  <li>Format preferences</li>
                  <li>Conversion success rates</li>
                </ul>
              </li>
              <li>Browser and device information for compatibility</li>
              <li>Anonymous conversion statistics</li>
              <li>No file-specific data is collected</li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">7. Security Measures</h2>
            <p>We implement various security measures to protect your privacy:</p>
            <ul className="features-list">
              <li>Local file processing ensures security</li>
              <li>SSL encryption for website access</li>
              <li>Regular security updates</li>
              <li>File validation and sanitization</li>
              <li>Memory management for secure processing</li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">8. Third-Party Services</h2>
            <p>Each service has its own privacy policy and data collection practices. We recommend reviewing:</p>
            <ul className="features-list">
              <li><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google Privacy Policy</a></li>
              <li><a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">Google Ads Policy</a></li>
            </ul>
          </div>

          <div className="text-section">
            <h2 className="subtitle">9. Policy Updates</h2>
            <p>We may update this privacy policy from time to time. Changes will be posted on this page with an updated revision date.</p>
          </div>

          <div className="text-section">
            <h2 className="subtitle">10. Contact Us</h2>
            <p>If you have any questions about our privacy policy or data practices, please contact us at srirsk.business@gmail.com</p>
          </div>
        </div>
      </div>

      <style jsx>{`
        .wrapper {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }

        .page-container {
          flex: 1;
          box-sizing: border-box;
          width: 100%;
          background-color: white;
        }

        .content {
          box-sizing: border-box;
          max-width: 800px;
          margin: 0 auto;
          font-family: "Lexend", sans-serif;
          padding: 4.44vh 2.22vh;
        }

        .title {
          font-family: "DM Sans", sans-serif;
          font-size: 3.33vh;
          font-weight: bold;
          color: #1b1f26;
          margin-bottom: 2.22vh;
          text-align: center;
        }

        .last-updated {
          color: #666;
          font-size: 1.67vh;
          margin-bottom: 2.22vh;
          text-align: center;
        }

        .subtitle {
          font-family: "DM Sans", sans-serif;
          font-size: 2.22vh;
          font-weight: bold;
          color: #1b1f26;
          margin-bottom: 1.11vh;
        }

        .subtitle-small {
          font-family: "DM Sans", sans-serif;
          font-size: 1.89vh;
          font-weight: bold;
          color: #1b1f26;
          margin-top: 1.67vh;
        }

        .text-section {
          margin-bottom: 2.22vh;
          padding: 1.67vh;
          background-color: #f8f9fa;
          border-radius: 0.83vh;
        }

        .text-section p {
          font-size: 1.67vh;
          line-height: 1.6;
          color: #666;
        }

        .features-list {
          list-style-type: disc;
          padding-left: 2.22vh;
          margin: 1.11vh 0;
        }

        .features-list li {
          font-size: 1.67vh;
          color: #666;
          margin: 0.83vh 0;
          line-height: 1.4;
        }

        .features-list li ul {
          margin: 0.56vh 0 0.56vh 1.67vh;
        }

        strong {
          color: #1b1f26;
          font-weight: 500;
        }

        .link-button {
          color: #2563eb;
          background: none;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          text-decoration: none;
          transition: color 0.3s ease;
        }

        .link-button:hover {
          color: #1d4ed8;
          text-decoration: underline;
        }

        a {
          color: #2563eb;
          text-decoration: none;
          transition: color 0.3s ease;
        }

        a:hover {
          color: #1d4ed8;
          text-decoration: underline;
        }

        @media (max-width: 767px) {
          .content {
            padding: 3.33vh 1.67vh;
          }

          .title {
            font-size: 2.78vh;
          }

          .text-section {
            padding: 1.11vh;
          }
        }
      `}</style>
    </div>
  );
};

export default Privacy;